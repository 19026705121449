const RIGHT_ALIGNED_TYPES = [
  'integer',
  'decimal',
  'numeric',
  'formula_service',
  'percentage',
  'formula',
  'lookup',
  'datasheet_filter'
]

const LEFT_ALIGNED_TYPES = ['string', 'text', 'multiline_text']

const CENTER_ALIGNED_FIELDS = [
  'id',
  'request_id',
  'area',
  'subarea',
  'progress',
  'discipline',
  'discipline.description',
  'label',
]

const DROP_COLUMN_TYPES = ['cascade_drop', 'conditional_cascade_drop', 'drop', 'multiple_drop']

const DATASHEET_FILTER_TYPES = ['datasheet_filter', 'multiple_datasheet_filter']

// eslint-disable-next-line import/prefer-default-export
export { RIGHT_ALIGNED_TYPES, LEFT_ALIGNED_TYPES, CENTER_ALIGNED_FIELDS, DROP_COLUMN_TYPES, DATASHEET_FILTER_TYPES }
